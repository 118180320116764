import { get, isEmpty, isNil, omit, pick, set, unset } from "lodash";
import qs from 'qs';
import { logError } from "../../../infrastructure/logging/Logger";
import { API } from "../../../infrastructure/network/HttpClient";
import {
  ACCESS_TOKEN_LEAD_KEY, ENTITY_FIELDS_KEYS, EXCEPTIONAL_DATACOLLECTION_MAPPING, STORAGE_KEY, STORAGE_TYPE
} from "../../utilties/Constants";
import { OfflineStorage } from "../../utilties/OfflineStorage";
import { checkIfArray, checkIfObject, getNameById } from "../../utilties/Utils";
var jwt_decode = require("jwt-decode");
export const Services = {
  getCustomMaster: function(clientConfiguration){
    if(!checkIfObject(clientConfiguration)){
      console.error("Error: client configuration is not loaded and passed for customMaster");
    }
    let customMasterFileName = "";
    if(clientConfiguration[STORAGE_KEY.REQUEST_CUSTOM_MASTER_FROM_GZIP]){
      customMasterFileName = window.extraaedge.client.pageConfiguration.customMasterDataGZIPPath;
    }else if(clientConfiguration[STORAGE_KEY.REQUEST_CUSTOM_MASTER_FROM_JSON]){
      customMasterFileName = window.extraaedge.client.pageConfiguration.customMasterDataJSONPath;
    }
    if(customMasterFileName){
      try{
        return fetch(customMasterFileName)
        .then((response) => response.json())
        .then((jsonData) => {
          return {
            data: jsonData,
            status: 200,
          };
        })
        .catch((error) => {
          console.error("Error while fetching the custom master data file",error);
        });
      }catch(error){
        console.error("Error while fetching the custom master data file",error);
      }
    }else{
      return {
        data: {},
        status: 400,
      };
    }
  },
  getAllMaster: function (clientConfiguration) {
    let isGenerateFile=false;
    let clientInfo = null;   
    let clientId = "",alias="",crm_clientId="",crm_alias = "",requiremasterfields="",masterDataFileName="";
    if(clientConfiguration){
      clientInfo = clientConfiguration[STORAGE_KEY.CLIENT_INFO] || null;      
      if(clientConfiguration[STORAGE_KEY.IS_MASTER_DATA_FROM_GZIP_FEATURE_ACTIVE]){
        //GZIP active found set file names of master data and store setting to access
        if(clientConfiguration[STORAGE_KEY.REQUEST_MASTERDATA_FROM_GZIP] && !!get(window, "extraaedge.client.pageConfiguration.masterDataGZIPPath"))
        masterDataFileName=window.extraaedge.client.pageConfiguration.masterDataGZIPPath;
      }else if(clientConfiguration[STORAGE_KEY.IS_MASTER_DATA_FROM_JSON_FEATURE_ACTIVE]){
        //GZIP active found set file names of master data and store setting to access
        if(clientConfiguration[STORAGE_KEY.REQUEST_MASTERDATA_FROM_JSON] && !!get(window, "extraaedge.client.pageConfiguration.masterDataJSONPath"))
        masterDataFileName=window.extraaedge.client.pageConfiguration.masterDataJSONPath;
      }
      if(clientInfo){
        clientId = clientInfo[STORAGE_KEY.CLIENT_ID] || "";        
        alias =  clientInfo[STORAGE_KEY.ALIAS] || "";
        crm_alias = (clientInfo[STORAGE_KEY.CRM_ALIAS] || alias);
        crm_clientId = (clientInfo[STORAGE_KEY.CRM_CLIENT_ID] || clientId);
      }
      requiremasterfields = clientConfiguration[STORAGE_KEY.REQUEST_MASTER_DATA_FOR_FIELDS] || "";
      isGenerateFile = masterDataFileName && clientConfiguration[STORAGE_KEY.IS_GENERATE_MASTER_DATA_FILE_API_KEY] ? clientConfiguration[STORAGE_KEY.IS_GENERATE_MASTER_DATA_FILE_API_KEY] : false;
    }
    unset(
      clientConfiguration,
      STORAGE_KEY.IS_GENERATE_MASTER_DATA_FILE_API_KEY
    );
    if (
      masterDataFileName && 
      !isGenerateFile
    ) {
      //call JSON for master data
        masterDataFileName = masterDataFileName.replace(new RegExp("/" + alias + "/", "g"), "/" + crm_alias + "/")
        try{
          return fetch(masterDataFileName)
          .then((response) => response.json())
          .then((jsonData) => {
            // jsonData is parsed json object received from url
            return {
              data: jsonData,
              status: 200,
              isMasterDataFromJSON: true,
            };
          })
          .catch((error) => {
            // handle your errors here
            !isNil(clientConfiguration) &&
              set(
                clientConfiguration,
                STORAGE_KEY.IS_GENERATE_MASTER_DATA_FILE_API_KEY,
                true
              );
            console.error(error);
            return Services.getAllMaster(clientConfiguration);
          });
        }catch(ex){
          console.error(ex);
          return new Error(`Master data JSON/GZIP path not found`);
        }    
    } else {
      //call API for master data
      let api = masterDataFileName
        ? !!get(window, "extraaedge.API_END_POINT.getAllMasterJSON")
          ? window.extraaedge.API_END_POINT.getAllMasterJSON
          : "/common/GenerateRequiredMasterJSON"
        : !!get(window, "extraaedge.API_END_POINT.getAllMaster")
        ? window.extraaedge.API_END_POINT.getAllMaster
        : "/common/GetRequiredMasters";
      const exceptionRequiredMasterFields = EXCEPTIONAL_DATACOLLECTION_MAPPING ? Object.keys(EXCEPTIONAL_DATACOLLECTION_MAPPING) :null;
      if(checkIfArray(exceptionRequiredMasterFields)){
        let requiremasterfieldsArr = requiremasterfields.split(",");
        exceptionRequiredMasterFields.reduce((result,item)=>{
          if(result.indexOf(item)!==-1){
            result.splice(result.indexOf(item),1,EXCEPTIONAL_DATACOLLECTION_MAPPING[item])
          }            
          return result;
        },requiremasterfieldsArr);
        requiremasterfields = requiremasterfieldsArr.join(",");
      }
      let params = {
        requiremasterfields,
        clientId: crm_clientId.toLowerCase(),
      };
      if(masterDataFileName){
        set(params, "isFetchFromDB", true);
        set(params, "type", "application");
        set(params, "isGenerateFile", isGenerateFile);
      }        
      return API.get(api, { params });
    }
  },
  getStoreSetting: function (clientConfiguration) {
    let isGenerateFile=false;
    let clientInfo = null;   
    let clientId = "",storeSettingFileName="";
    if(checkIfObject(window.properties)) {
      return {
        status: 200,
        data: window.properties
      }
    }
    if(clientConfiguration){
      clientInfo = clientConfiguration[STORAGE_KEY.CLIENT_INFO] || null;      
      if(clientConfiguration[STORAGE_KEY.IS_MASTER_DATA_FROM_GZIP_FEATURE_ACTIVE]){
        //GZIP active found set file names of master data and store setting to access
        if(clientConfiguration[STORAGE_KEY.REQUEST_STORE_SETTING_FROM_GZIP] && !!get(window, "extraaedge.client.pageConfiguration.storeSettingGZIPPath"))
        storeSettingFileName = window.extraaedge.client.pageConfiguration.storeSettingGZIPPath;
      }else if(clientConfiguration[STORAGE_KEY.IS_MASTER_DATA_FROM_JSON_FEATURE_ACTIVE]){
        //GZIP active found set file names of master data and store setting to access
        if(clientConfiguration[STORAGE_KEY.REQUEST_STORE_SETTING_FROM_JSON] && !!get(window, "extraaedge.client.pageConfiguration.storeSettingJSONPath"))
        storeSettingFileName = window.extraaedge.client.pageConfiguration.storeSettingJSONPath;
      }
      if(clientInfo){
        clientId =  clientInfo[STORAGE_KEY.CLIENT_ID] || "";
      }
      isGenerateFile = storeSettingFileName && clientConfiguration[STORAGE_KEY.IS_GENERATE_STORE_SETTING_FILE_API_KEY] ? clientConfiguration[STORAGE_KEY.IS_GENERATE_STORE_SETTING_FILE_API_KEY] : false;
    }
    
    unset(clientConfiguration, STORAGE_KEY.IS_GENERATE_STORE_SETTING_FILE_API_KEY);
    /**************************** */
    if (
      storeSettingFileName &&
      !isGenerateFile
    ) {
      //call JSON for store setting
      return fetch(storeSettingFileName)
        .then((response) => response.json())
        .then((jsonData) => {
          // jsonData is parsed json object received from url
          return {
            data: jsonData,
            status: 200,
          };
        })
        .catch((error) => {
          // handle your errors here
          console.error(error);
          !isNil(clientConfiguration) &&
            set(
              clientConfiguration,
              STORAGE_KEY.IS_GENERATE_STORE_SETTING_FILE_API_KEY,
              true
            );
          return Services.getStoreSetting(clientConfiguration);
        });
    } else {
      //call API for store setting
      let api = storeSettingFileName
        ? !!get(window, "extraaedge.API_END_POINT.getAllMasterSettingJSON")
          ? window.extraaedge.API_END_POINT.getAllMasterSettingJSON
          : "/common/GenerateStoreSettingJSON"
        : !!get(window, "extraaedge.API_END_POINT.getAllMasterSetting")
        ? window.extraaedge.API_END_POINT.getAllMasterSetting
        : "/common/GetStoreSettings";
      let params = {
        clientId: clientId.toLowerCase(),
      };
      if(storeSettingFileName){
        set(params, "isFetchFromDB", true);
        set(params, "type", "storesetting");
        set(params, "isGenerateFile", isGenerateFile);
      }
      return API.get(api, { params });
    }
  },
  getClientInfo: function (url) {
    /*return API.get(
      url
    );*/
    /*
         .then(function(response) {
           return response.json();
         })
         .then(function(myJson) {
           return myJson;
         })
         .catch(function(e) {
           logError("config error", e);
           return e;
         });*/
    /* return API.get(
      url
    );*/
    return fetch(url)
      .then((response) => response.json())
      .then((jsonData) => {
        // jsonData is parsed json object received from url
        return jsonData;
      })
      .catch((error) => {
        // handle your errors here
        console.error(error);
        return null;
      });
  },
  validateLogin: function (param) {
    let fd = new FormData();
    if (param && Object.keys(param).length) {
      Object.keys(param).map((key) => {
        fd.append(key, param && !!param[key] ? param[key] : "");
        return true;
      });
    }
    fd.append("deviceType", "Web");
    //"Web" - identify device logic need to add here
    let config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        withCredentials: true,
      },
    };
    if (
      window &&
      window.extraaedge &&
      window.extraaedge.API_END_POINT &&
      !!window.extraaedge.API_END_POINT.TOKEN_URL
    ) {
      config["baseURI"] = window.extraaedge.API_END_POINT.TOKEN_URL;
    }
    return API.post(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.login
        ? window.extraaedge.API_END_POINT.login
        : "",
      fd,
      config
    );
  },
  getUserDetail: function (clientInfo) {
    let accessToken = OfflineStorage.getItem(
      STORAGE_KEY.ACCESS_TOKEN,
      STORAGE_TYPE.LOCAL
    );
    let lid = OfflineStorage.getItem(
      STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID,
      STORAGE_TYPE.LOCAL
    );
    let tokenDecoded = jwt_decode(accessToken);
    return API.get(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.getuserdetails
        ? `${window.extraaedge.API_END_POINT.getuserdetails}${
            !!lid ? lid : tokenDecoded[ACCESS_TOKEN_LEAD_KEY]
          }`
        : `leads/leadApplicationDetails/${
            !!lid ? lid : tokenDecoded[ACCESS_TOKEN_LEAD_KEY]
          }`
    );
  },
  updateUserDetail: function (leadObject, masterData) {
    if (leadObject && leadObject.lead) {
      leadObject.lead["SendWelcomeEmail"] = false; //to avoid send email while edit lead
      leadObject.lead["SendWelcomeSms"] = false; //to avoid send SMS while edit lead
      leadObject.lead["eeSourceId"] = 16; //set 16 as we need to track even counselor edited from applicaiton from or crm - 16 means Applicaiton form so use here.
      
    }
    if (
      leadObject &&
      !!leadObject.metaData &&
      Object.keys(leadObject.metaData).length
    ) {
      leadObject.metaData["categoryId"] = 1;
    }
    ENTITY_FIELDS_KEYS.forEach((entity) => {
      if(checkIfArray(get(leadObject, `lead.${entity}Id`))){
        const entityIds = get(leadObject, `lead.${entity}Id`);
        const entityIdName = getNameById([entityIds[0]],get(masterData, entity))
        if(entityIdName){
          set(leadObject, `lead.${entity}Name`, entityIdName);
        }
      }
    });
    return API.post(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.updateuserdetails
        ? window.extraaedge.API_END_POINT.updateuserdetails
        : "leads/leadDetails/editAppForm",
      leadObject
    ).then(response => ({ ...response }))
    .catch(error => { 
      if(error && error.response){
        return error.response;
      }
      return new Error(error);
    });
  },
  requestGeneratePDF: function (param) {
    let isParamAdded = true;
    if (param) {
      let clientInfo = OfflineStorage.getItem(
        STORAGE_KEY.CLIENT_INFO_REDUCER,
        STORAGE_TYPE.LOCAL,
        false
      );
      if (!!clientInfo) {
        clientInfo = JSON.parse(clientInfo);
        if (clientInfo.Info && !!clientInfo.Info.authToken) {
          param["AuthToken"] = clientInfo.Info.authToken;
        } else if (isParamAdded) isParamAdded = false;
        if (clientInfo.Info && !!clientInfo.Info.source) {
          param["Source"] = clientInfo.Info.source;
        } else if (isParamAdded) isParamAdded = false;
      }
      !!OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL) &&
        set(
          param,
          STORAGE_KEY.INST,
          OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL)
        );

      !!OfflineStorage.getItem(
        STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
        STORAGE_TYPE.LOCAL
      ) &&
        set(
          param,
          STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
          OfflineStorage.getItem(
            STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
            STORAGE_TYPE.LOCAL
          )
        );

      /**
     * AuthToken = paymentRequest.AuthToken,
               Source = paymentRequest.Source,
               LeadId = paymentRequest.LeadId,
               ReferenceKey = paymentRequest.ReferenceKey,
     */
      if (isParamAdded) {
        return API.post(
          window &&
            window.extraaedge &&
            window.extraaedge.API_END_POINT &&
            !!window.extraaedge.API_END_POINT.generatePDF
            ? window.extraaedge.API_END_POINT.generatePDF
            : "api/WebHook/AfterPaymentGeneratePDF",
          param
        );
      } else {
        logError(
          new Error("Generate PDF requested without/incorrect parameters")
        );
      }
    }
  },
  updateUserMetaData: function (leadObject) {
    return API.post(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.updateMetaData
        ? window.extraaedge.API_END_POINT.updateMetaData
        : "/leads/updateMetaData",
      leadObject
    );
  },
  requestGeneratePreviewPDF: function (param) {
    let isParamAdded = true;
    if (!(param && !isNil(param.id))) {
      isParamAdded = false;
    }

    let requestParams = pick(param, "id");

    if (isParamAdded) {
      return API.get(
        window &&
          window.extraaedge &&
          window.extraaedge.API_END_POINT &&
          !!window.extraaedge.API_END_POINT.generatePreviewPDF
          ? window.extraaedge.API_END_POINT.generatePreviewPDF
          : "/leads/PdfDetails",
        {
          params: requestParams,
        }
      );
    } else {
      logError(
        new Error("Generate Preview PDF requested without/incorrect parameters")
      );
    }
  },
  uploadFile: function (params, fd) {
    return API.post(params, fd);
  },
  requestGenerateTemplatePDF: function (param) {
    let isParamAdded = true;
    if (param) {
      let clientInfo = OfflineStorage.getItem(
        STORAGE_KEY.CLIENT_INFO_REDUCER,
        STORAGE_TYPE.LOCAL,
        false
      );
      if (!!clientInfo) {
        clientInfo = JSON.parse(clientInfo);
        if (clientInfo.Info && !!clientInfo.Info.authToken) {
          param["AuthToken"] = clientInfo.Info.authToken;
        } else if (isParamAdded) isParamAdded = false;
        if (clientInfo.Info && !!clientInfo.Info.source) {
          param["Source"] = clientInfo.Info.source;
        } else if (isParamAdded) isParamAdded = false;
      }
      !!OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL) &&
        set(
          param,
          STORAGE_KEY.INST,
          OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL)
        );

      !!OfflineStorage.getItem(
        STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
        STORAGE_TYPE.LOCAL
      ) &&
        set(
          param,
          STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
          OfflineStorage.getItem(
            STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
            STORAGE_TYPE.LOCAL
          )
        );

      /**
     * AuthToken = paymentRequest.AuthToken,
               Source = paymentRequest.Source,
               LeadId = paymentRequest.LeadId,
               ReferenceKey = paymentRequest.ReferenceKey,
     */
      if (isParamAdded) {
        return API.post(
          window &&
            window.extraaedge &&
            window.extraaedge.API_END_POINT &&
            !!window.extraaedge.API_END_POINT.generateTemplatePDF
            ? window.extraaedge.API_END_POINT.generateTemplatePDF
            : "api/WebHook/generateTemplatePDF",
          param
        );
      } else {
        logError(
          new Error(
            "Generate Template PDF requested without/incorrect parameters"
          )
        );
      }
    }
  },
  getConuselorInfo: function (cid) {
    return API.get(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.getCounselorDetail
        ? `${window.extraaedge.API_END_POINT.getCounselorDetail}/?cid=${cid}`
        : `users/getCounselorDetail/?cid=${cid}`
    );
  },
  
  pushAppForm: function (queryString) {
    return API.get(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.pushAppForm
        ? `${window.extraaedge.API_END_POINT.pushAppForm}${queryString}`
        : `/leads/PushAppForm${queryString}`
    );
  },
  requestAddLeadEvent: function (param) {
    if (param) {
      const eventType =
        !isNil(get(param, `eventType`)) && !isEmpty(get(param, `eventType`))
          ? param.eventType
          : null;
      const api = !!eventType
        ? eventType === "add" &&
          !isNil(get(window, `extraaedge.API_END_POINT.addLeadEvent`)) &&
          !isEmpty(get(window, `extraaedge.API_END_POINT.addLeadEvent`))
          ? window.extraaedge.API_END_POINT.addLeadEvent
          : eventType === "update" &&
            !isNil(get(window, `extraaedge.API_END_POINT.addOrEditEvent`)) &&
            !isEmpty(get(window, `extraaedge.API_END_POINT.addOrEditEvent`))
          ? window.extraaedge.API_END_POINT.addOrEditEvent
          : null
        : null;
      if (!!api) {
        return API.post(api, omit(param, ["eventType"]));
      } else {
        logError(
          new Error(
            "Parameters are missing or incorrect for Add lead event API requested"
          )
        );
      }
    }
  },

  checkValuesFromHistory: function (parameters) {
    if (
      !!!!get(parameters, "leadId") &&
      !isNil(get(parameters, "values")) &&
      Array.isArray(get(parameters, "values")) &&
      parameters.values.length > 0
    ) {
      return API.get(
        window &&
          window.extraaedge &&
          window.extraaedge.API_END_POINT &&
          !!window.extraaedge.API_END_POINT.checkValuesInHistory
          ? window.extraaedge.API_END_POINT.checkValuesInHistory
          : "/leads/checkValuesInHistory",
        {
          params: { leadId:get(parameters, "leadId"), values: parameters.values },
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        }
      );
    } else {
      return null;
    }
  },

  getPaymentDetailList: function (params) {
    if (params) {
      return API.get(
        window &&
          window.extraaedge &&
          window.extraaedge.API_END_POINT &&
          !!window.extraaedge.API_END_POINT.getPaymentDetailList
          ? window.extraaedge.API_END_POINT.getPaymentDetailList +  `?${params}`
          : `/payment/getPaymentDetailList?${params}`
      );
    } else {
      return null;
    }
  },
  getDomainConfig: function (url) {
    return fetch(url)
      .then((response) => response.json())
      .then((jsonData) => {
        // jsonData is parsed json object received from url
        return jsonData;
      })
      .catch((error) => {
        // handle your errors here
        console.error(error);
        return error;
      });
  },
  getActivities: function (params) {
    if (params) {
      return API.get(
        get(window, `extraaedge.API_END_POINT.getActivitiesByLeadId`)
          ? window.extraaedge.API_END_POINT.getActivitiesByLeadId +  `?${params}`
          : `/LeadActivities/GetActivitiesByLeadId?${params}`
      );
    } else {
      return null;
    }
  },
  callGetAllApllcationLeadsAPI: function() {
    return API.get(
      get(window, `extraaedge.API_END_POINT.getAllApplicationLead`)
        ? window.extraaedge.API_END_POINT.getAllApplicationLead 
        : `/leads/getAllApplicationLeads`
    );
  },
  callGetLeadDocumentsAPI: function(leadId) {
    return API.get(
      get(window, `extraaedge.API_END_POINT.getLeadDocuments`)
        ? window.extraaedge.API_END_POINT.getLeadDocuments +  `?leadId=${leadId}&masterKey=1`
        : `/leads/leadDocuments?leadId=${leadId}&masterKey=1`
    );
  },
  getLeadT: function(str) {
    return API.get(
      get(window, `extraaedge.API_END_POINT.getLeadT`)
        ? `${window.extraaedge.API_END_POINT.getLeadT}?key=${str}`
        : `/leads/token/create?key=${str}`
    );
  }
};
